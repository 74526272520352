import { tns } from 'tiny-slider'

const jumbotronSlider = document.querySelector('#jumbotron-slider')
if (jumbotronSlider) {
  tns({
    container: '#jumbotron-slider',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    nav: false,
    prevButton: '#jumbotron-slider-prev',
    nextButton: '#jumbotron-slider-next',
    loop: true,
  })
}

const mediaSlider = document.querySelector('#media-slider')
if (mediaSlider) {
  tns({
    container: '#media-slider',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    nav: false,
    prevButton: '#media-slider-prev',
    nextButton: '#media-slider-next',
    loop: true,
  })
}

const clientTab = document.querySelector('#client-tab')

if (clientTab) {
  const tabs = clientTab.querySelectorAll('.tab')
  const tabContents = clientTab.querySelectorAll('.tab-content')

  tabs.forEach(function (tab, index) {
    tab.addEventListener('click', function () {
      if (tab.classList.contains('active')) {
        return
      }
      const activeTab = clientTab.querySelector('.tab.active')
      activeTab.classList.remove('active')
      const activeTabContent = clientTab.querySelector('.tab-content.active')
      activeTabContent.classList.remove('active')
      tab.classList.add('active')
      tabContents[index].classList.add('active')
    })
  })
}

const [red, green, blue] = [26, 26, 39]
const header = document.querySelector('#header')
const menuBtn = document.querySelector('#menu-btn')
const menu = document.querySelector('#menu')

window.addEventListener('scroll', () => {
  if (!menu.classList.contains('hidden')) {
    header.style.backgroundColor = `rgba(${red}, ${green}, ${blue}, 1)`
    return
  }
  header.style.backgroundColor = `rgba(${red}, ${green}, ${blue}, ${
    window.pageYOffset / 320
  })`
})

menuBtn.addEventListener('click', (event) => {
  menu.classList.toggle('hidden')
  if (!menu.classList.contains('hidden')) {
    header.style.backgroundColor = `rgba(${red}, ${green}, ${blue}, 1)`
  } else {
    header.style.backgroundColor = `rgba(${red}, ${green}, ${blue}, ${
      window.pageYOffset / 320
    })`
  }
  event.stopPropagation()
})
window.addEventListener('click', () => {
  menu.classList.add('hidden')
  header.style.backgroundColor = `rgba(${red}, ${green}, ${blue}, ${
    window.pageYOffset / 320
  })`
})

const form = document.getElementById('contact-form')

if (form) {
  const submitBtn = document.getElementById('contact-form-btn')
  const submitMessage = document.getElementById('submit-success')

  async function handleSubmit(event) {
    event.preventDefault()
    submitBtn.setAttribute('disabled', true)

    const data = new FormData(event.target)

    fetch(event.target.action, {
      method: form.method,
      body: data,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(() => {
        submitMessage.classList.remove('hidden')
        setTimeout(() => {
          submitMessage.classList.add('hidden')
        }, 3000)
      })
      .finally((response) => {
        submitBtn.removeAttribute('disabled')
        form.reset()
      })
  }

  form.addEventListener('submit', handleSubmit)
}
